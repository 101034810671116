import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductComponent } from './_components/product/product.component';
import { BuiltInVariantComponent } from './_components/built-in-variant/built-in-variant.component';
import { DimensionsComponent } from './_components/dimensions/dimensions.component';
import { ResultComponent } from './result/result.component';
import { ContourWidthComponent } from './_components/contour-width/contour-width.component';
import { ContourShapeComponent } from './_components/contour-shape/contour-shape.component';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ConfiguratorComponent } from './configurator/configurator.component';
import { ApiService } from "./_services/api.service";
import { FormsModule } from "@angular/forms";
import { DCTooltipModule } from "./_components/tooltip/tooltip.module";
import { NgSelectModule } from "@ng-select/ng-select";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ProductComponent,
    BuiltInVariantComponent,
    DimensionsComponent,
    ResultComponent,
    ContourWidthComponent,
    ContourShapeComponent,
    ConfiguratorComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        PerfectScrollbarModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        FormsModule,
        DCTooltipModule,
        NgSelectModule
    ],
  providers: [ApiService],
  bootstrap: [AppComponent]
})
export class AppModule { }
