import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Config } from "../../_classes/config";
import { ConfiguratorService } from "../../_services/configurator.service";

@Component({
  selector: 'bcc-dimensions',
  templateUrl: './dimensions.component.html'
})
export class DimensionsComponent implements OnInit {

  @ViewChild('canvas') canvas;
  @Input() selectedLanguage: string;

  showLoading: boolean = false;
  config: Config;

  element: any;
  elementChanges: any[] = [];

  ctx: any;
  elementRef: any;

  newElementWidth: 0;
  newElementWidthSecond: 0;
  newElementHeight: 0;
  newElementHeightSecond: 0;
  inputHeight = 42;
  inputWidth = 142;

  heightLength: number;
  heightLengthSecond: number;
  widthLength: number;
  widthLengthSecond: number;
  suggestionsHeight: any[] = [];
  suggestionsHeightSecond: any[] = [];
  suggestionsWidth: any[] = [];
  suggestionsWidthSecond: any[] = [];
  dimensionLimit = 999999999;

  patternZCanvasCorrection = 25;

  buttonDisabled: boolean = true;
  showResetConfirmation: boolean = false;
  showDimensionAlert: boolean = false;

  cursor = {
    x: 0,
    y: 0
  }

  // Old colors
  // fd9fc6 / fill
  // c51d63 / stroke

  canvasConfig = {
    scale: 1,
    height: 600,
    width : 600,
    border: 2,
    fillStyle: '#D54583',
    strokeStyle: '#D54583',
    spaceBetweenBorders: 6,
    positionTop: 70
  }

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private configuratorService: ConfiguratorService
  ) { }

  ngOnInit() {
    this.getQueryParams();
    this.getElement();
    this.configuratorService.clearCanvasUrl();
    if (window.innerHeight <= 600) {
      this.canvasConfig.height = innerHeight/3.2;
      this.canvasConfig.width = innerHeight/3.2;
    } else if (window.innerHeight >= 978) {
      this.canvasConfig.height = innerHeight/2.2;
      this.canvasConfig.width = innerHeight/2.2;
    } else {
      this.canvasConfig.height = innerHeight/2.8;
      this.canvasConfig.width = innerHeight/2.8;
    }
  }

  getQueryParams() {
    this.router.queryParams.subscribe((params: Config) => {
      this.config = params
    })
  }

  getElement() {
    this.showLoading = true;
    this.configuratorService.getDimensions(this.config.productId, this.config.patternId)
      .subscribe(
        (element) => {
          this.element = element;
          this.scaleElement(element);
          this.elementRef = this.canvas.nativeElement;
          this.ctx = this.elementRef.getContext('2d');
          this.elementRef.width = this.canvasConfig.width;
          this.elementRef.height = this.canvasConfig.height;
          this.drawElement();
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      )
  }

  drawElement(measureLines: boolean = true) {
    this.newElementHeight = 0;
    this.newElementHeightSecond = 0;
    this.newElementWidth = 0;
    this.element.lines.map((line) => {
      const segmentsLength = line.segments.reduce((a,b) => a + b , 0);
      if (line.angle === 270) { this.newElementHeight = segmentsLength }
      if (line.angle === 90) { this.newElementHeightSecond = segmentsLength }
      if (line.angle === 0) { this.newElementWidth = segmentsLength }
      if (line.angle === 0 && !line.leftConnector) { this.newElementWidthSecond = segmentsLength }
    })
    if (this.config.patternId != 5) {
      this.cursor.x = this.elementRef.width / 2 - this.newElementWidth/2;
    } else {
      this.cursor.x = this.patternZCanvasCorrection;
    }
    if (this.newElementHeight < this.newElementHeightSecond) {
      this.cursor.y = this.newElementHeightSecond - this.newElementHeight;
    }
    this.element.lines.map((line) => {
      const segmentsLength = line.segments.reduce((a,b) => a + b , 0);
      this.drawLine(line, measureLines);
      if (this.config.patternId == 1 && this.elementChanges.length < 1) { this.elementChanges.push({line_id: line.lineId, length: segmentsLength});}
      if (this.config.patternId == 2 && this.elementChanges.length < 2) { this.elementChanges.push({line_id: line.lineId, length: segmentsLength});}
      if (this.config.patternId == 3 && this.elementChanges.length < 4) { this.elementChanges.push({line_id: line.lineId, length: segmentsLength});}
      if (this.config.patternId == 4 && this.elementChanges.length < 3) { this.elementChanges.push({line_id: line.lineId, length: segmentsLength});}
      if (this.config.patternId == 5 && this.elementChanges.length < 3) { this.elementChanges.push({line_id: line.lineId, length: segmentsLength});}
    })
  }

  drawLine(line, measureLines: boolean = true) {
    line.segments.map((segment) => {
        this.drawSegment(segment, line.angle);
    })
    if (measureLines) { this.drawMeasureLine(line); }
  }

  drawMeasureLine(line) {
    const length = line.segments.reduce((a,b) => a + b , 0);
    const stubLength = 10;
    const distance = 10;
    let spaceBetweenBorders = 0;
    this.ctx.beginPath();
    this.ctx.save();
    if (line.leftConnector) { spaceBetweenBorders = this.canvasConfig.border + this.canvasConfig.spaceBetweenBorders; }

    if (line.angle === 0) {
      if (line.lineId === 11) {
        const bordersAdditional = this.canvasConfig.border + this.canvasConfig.spaceBetweenBorders;
        this.ctx.moveTo(this.cursor.x - length + bordersAdditional,this.cursor.y - distance - stubLength + this.canvasConfig.positionTop);
        this.ctx.lineTo(this.cursor.x - length + bordersAdditional,this.cursor.y - stubLength*2 - distance + this.canvasConfig.positionTop)
        this.ctx.moveTo(this.cursor.x - length + bordersAdditional ,this.cursor.y - distance - stubLength*1.5 + this.canvasConfig.positionTop);
        this.ctx.lineTo(this.cursor.x + bordersAdditional ,this.cursor.y - distance - stubLength*1.5 + this.canvasConfig.positionTop)
        this.ctx.moveTo(this.cursor.x + bordersAdditional,this.cursor.y - distance - stubLength + this.canvasConfig.positionTop);
        this.ctx.lineTo(this.cursor.x + bordersAdditional,this.cursor.y - stubLength*2 - distance + this.canvasConfig.positionTop)
      } else {
        this.ctx.moveTo(this.cursor.x - length,this.cursor.y + distance + this.canvasConfig.positionTop);
        this.ctx.lineTo(this.cursor.x - length,this.cursor.y + stubLength + distance + this.canvasConfig.positionTop)
        this.ctx.moveTo(this.cursor.x - length ,this.cursor.y + distance + (stubLength / 2) + this.canvasConfig.positionTop);
        this.ctx.lineTo(this.cursor.x + spaceBetweenBorders ,this.cursor.y + distance + (stubLength / 2) + this.canvasConfig.positionTop)
        this.ctx.moveTo(this.cursor.x + spaceBetweenBorders,this.cursor.y + distance + this.canvasConfig.positionTop);
        this.ctx.lineTo(this.cursor.x + spaceBetweenBorders,this.cursor.y + stubLength + distance + this.canvasConfig.positionTop)
      }

    }
    else if (line.angle === 270) {
      if (line.lineId === 12) {
        this.ctx.moveTo(this.cursor.x - distance - this.newElementWidthSecond, this.cursor.y + this.canvasConfig.positionTop  - spaceBetweenBorders - length);
        this.ctx.lineTo(this.cursor.x - stubLength - distance - this.newElementWidthSecond,this.cursor.y + this.canvasConfig.positionTop - spaceBetweenBorders - length)
        this.ctx.moveTo(this.cursor.x - distance - (stubLength/2) - this.newElementWidthSecond, this.cursor.y + this.canvasConfig.positionTop  - spaceBetweenBorders - length);
        this.ctx.lineTo(this.cursor.x - distance - (stubLength / 2) - this.newElementWidthSecond, this.cursor.y + this.canvasConfig.positionTop);
        this.ctx.moveTo(this.cursor.x - distance - this.newElementWidthSecond, this.cursor.y + this.canvasConfig.positionTop);
        this.ctx.lineTo(this.cursor.x - stubLength - distance - this.newElementWidthSecond, this.cursor.y + this.canvasConfig.positionTop);
      } else {
        this.ctx.moveTo(this.cursor.x - distance, this.cursor.y + this.canvasConfig.positionTop  - spaceBetweenBorders - length);
        this.ctx.lineTo(this.cursor.x - stubLength - distance,this.cursor.y + this.canvasConfig.positionTop - spaceBetweenBorders - length)
        this.ctx.moveTo(this.cursor.x - distance - (stubLength/2), this.cursor.y + this.canvasConfig.positionTop  - spaceBetweenBorders - length);
        this.ctx.lineTo(this.cursor.x - distance - (stubLength / 2), this.cursor.y + this.canvasConfig.positionTop);
        this.ctx.moveTo(this.cursor.x - distance, this.cursor.y + this.canvasConfig.positionTop);
        this.ctx.lineTo(this.cursor.x - stubLength - distance, this.cursor.y + this.canvasConfig.positionTop);
      }
    }
    else if (line.angle === 90 && this.config.patternId == 4) {
      this.ctx.moveTo(this.cursor.x + stubLength + spaceBetweenBorders,this.cursor.y + length + this.canvasConfig.positionTop);
      this.ctx.lineTo(this.cursor.x + stubLength * 2 + spaceBetweenBorders,this.cursor.y + length + this.canvasConfig.positionTop)
      this.ctx.moveTo(this.cursor.x + distance + (stubLength/2) + spaceBetweenBorders, this.cursor.y + length + this.canvasConfig.positionTop);
      this.ctx.lineTo(this.cursor.x + distance + (stubLength / 2) + spaceBetweenBorders, this.cursor.y + this.canvasConfig.positionTop - spaceBetweenBorders);
      this.ctx.moveTo(this.cursor.x + stubLength + spaceBetweenBorders,this.cursor.y + this.canvasConfig.positionTop - spaceBetweenBorders);
      this.ctx.lineTo(this.cursor.x + stubLength * 2 + spaceBetweenBorders,this.cursor.y + this.canvasConfig.positionTop - spaceBetweenBorders)
    }
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = '#000'
    this.ctx.stroke();
    this.ctx.restore();
    this.ctx.closePath();
  }

  drawSegment(segment, angle) {
    const sin = Math.sin((angle - 90) * Math.PI/180);
    const cos = Math.cos((angle - 90) * Math.PI/180);
    const x = this.cursor.x + cos;
    const y = this.cursor.y + sin;
    const spaceBetweenBorders = this.canvasConfig.border + this.canvasConfig.spaceBetweenBorders;

    this.ctx.beginPath();
    this.ctx.save();
    this.ctx.setTransform(1,0,0,1,x, y + this.canvasConfig.positionTop)
    this.ctx.rotate((angle - 90) * Math.PI/180);
    if (angle === 0) {
      this.cursor.x += segment;
      this.ctx.rect(sin,spaceBetweenBorders, spaceBetweenBorders, segment);
    }
    if (angle === 90) {
      this.cursor.y -= segment;
      this.ctx.rect(spaceBetweenBorders - cos,-spaceBetweenBorders, -spaceBetweenBorders, -segment);
    }
    if (angle === 180) {
      this.cursor.x -= segment;
      this.ctx.rect(-spaceBetweenBorders - sin,cos, spaceBetweenBorders, segment);
    }
    if (angle === 270) {
      this.cursor.y += segment;
      this.ctx.rect(cos,0, -spaceBetweenBorders, -segment);
    }
    this.ctx.fillStyle = this.canvasConfig.fillStyle;
    this.ctx.fill();
    this.ctx.lineWidth = this.canvasConfig.border;
    this.ctx.strokeStyle = this.canvasConfig.strokeStyle;
    this.ctx.stroke();
    this.ctx.restore();
    this.ctx.closePath();
  }

  resetCanvas() {
     this.showResetConfirmation = false;
     this.cursor.x = 0;
     this.cursor.y = 0;
     this.heightLength = null;
     this.heightLengthSecond = null;
     this.widthLength = null;
     this.widthLengthSecond = null;
     this.suggestionsHeight = [];
     this.suggestionsHeightSecond = [];
     this.suggestionsWidth = [];
     this.suggestionsWidthSecond = [];
     this.buttonDisabled = true;
     this.getElement();
  }

  canvasWithoutMeasureLines() {
    this.cursor.x = 0;
    this.cursor.y = 0;
    this.ctx.beginPath();
    this.ctx.save();
    this.ctx.clearRect(0,0, this.canvasConfig.width, this.canvasConfig.height);
    this.ctx.stroke();
    this.ctx.restore();
    this.ctx.closePath();
    this.drawElement(false);
  }

  postDimensions() {
    this.canvasWithoutMeasureLines();
    const dataUrl = this.canvas.nativeElement.toDataURL();
    this.configuratorService.setCanvasUrl(dataUrl);
    this.configuratorService.setNewDimensions(this.element);
    this.route.navigate(['/result'], {queryParams: {dimensions: true}, queryParamsHandling: 'merge'})
  }

  getSuggestions(event, lineId) {
    const length = event.target.value;
    const params = {
      line_id: lineId,
      length: length,
      language: localStorage.getItem('bcc-language')
    }
    if (length > this.dimensionLimit) {
        this.showDimensionAlert = true;
        event.target.value = null;
        this.resetValues();
    } else {
      if (this.config.patternId == 5) {
        if (!params.length && lineId === this.element.lines[0].lineId) { this.suggestionsWidthSecond = [];}
        if (!params.length && lineId === this.element.lines[1].lineId) { this.suggestionsHeight = [];}
        if (!params.length && lineId === this.element.lines[2].lineId) { this.suggestionsWidth = [];}
      } else {
        if (!params.length && lineId === this.element.lines[0].lineId) { this.suggestionsHeight = [];}
        if (this.element.lines.length > 1 && !params.length && lineId === this.element.lines[1].lineId) { this.suggestionsWidth = [];}
        if (!params.length && this.element.lines[2] && lineId === this.element.lines[2].lineId) { this.suggestionsHeightSecond = [];}
      }

      if (params.length) {
        this.configuratorService.getDimensionSuggestions(this.config.productId, this.config.patternId, params)
          .subscribe(
            (newDimensions) => {
              if (newDimensions.measurementIsExact) {
                this.suggestionsHeight = [];
                this.suggestionsHeightSecond = [];
                this.suggestionsWidth = [];
                this.suggestionsWidthSecond = [];
                this.selectLength(length, lineId);
              }else {
                if (this.config.patternId != 5) {
                  if (lineId === this.element.lines[0].lineId) { this.suggestionsHeight = newDimensions.suggestions; }
                  if (this.element.lines[2] && lineId === this.element.lines[2].lineId) { this.suggestionsHeightSecond = newDimensions.suggestions;}
                  if (this.element.lines.length > 1 && lineId === this.element.lines[1].lineId) { this.suggestionsWidth = newDimensions.suggestions; }
                }else {
                  if (lineId === this.element.lines[1].lineId) { this.suggestionsHeight = newDimensions.suggestions; }
                  if (this.element.lines.length > 1 && lineId === this.element.lines[2].lineId) { this.suggestionsWidth = newDimensions.suggestions; }
                  if (lineId === this.element.lines[0].lineId) { this.suggestionsWidthSecond = newDimensions.suggestions;}
                }
              }
            },
            () => {}
          )
      }
    }
  }

  resetValues() {
    this.suggestionsHeight = [];
    this.suggestionsHeightSecond = [];
    this.suggestionsWidth = [];
    this.suggestionsWidthSecond = [];
    this.heightLength = null;
    this.heightLengthSecond = null;
    this.widthLength = null;
    this.widthLengthSecond = null;
  }

  selectLength(suggestion, lineId) {
    this.elementChanges.map((change) => {
      if (change.line_id === lineId) {
        change.length = suggestion;
      }
    })
    this.configuratorService.getDimensionSuggestions(this.config.productId, this.config.patternId, {selectedElements: this.elementChanges})
      .subscribe(
        (element) => {
          if (this.config.patternId != 5) {
            if (lineId === this.element.lines[0].lineId) { this.heightLength = suggestion;}
            if (this.element.lines[2] && lineId === this.element.lines[2].lineId) { this.heightLengthSecond = suggestion;}
            if (this.element.lines.length > 1 && lineId === this.element.lines[1].lineId) { this.widthLength = suggestion;}
          }else {
            if (lineId === this.element.lines[2].lineId) { this.widthLength = suggestion;}
            if (lineId === this.element.lines[1].lineId) { this.heightLength = suggestion;}
            if (lineId === this.element.lines[0].lineId) { this.widthLengthSecond = suggestion;}
          }

          if (this.config.patternId == 1 && this.heightLength) { this.buttonDisabled = false; }
          if ((this.config.patternId == 2 || this.config.patternId == 3) && this.heightLength && this.widthLength) { this.buttonDisabled = false; }
          if (this.config.patternId == 4 && this.heightLength && this.widthLength && this.heightLengthSecond) { this.buttonDisabled = false; }
          if (this.config.patternId == 5 && this.heightLength && this.widthLength && this.widthLengthSecond) { this.buttonDisabled = false; }
          this.suggestionsHeight = [];
          this.suggestionsHeightSecond = [];
          this.suggestionsWidth = [];
          this.suggestionsWidthSecond = [];
          this.element = element;
          this.cursor = {x: 0, y: 0}
          this.ctx.clearRect(0, 0, this.canvasConfig.width, this.canvasConfig.height);
          this.scaleElement(this.element);
          this.drawElement();
        },
        () => {}
      )
  }

  scaleElement(element) {
    const newLengths = [];
    element.lines.map((line) => {
      const length = line.segments.reduce((a,b) => a + b , 0);
      newLengths.push(length);
    })
    if (this.config.patternId == 5) {
      newLengths[0] = newLengths[0] + newLengths[2];
    }
    this.canvasConfig.scale = (this.canvasConfig.height - 100) / Math.max(...newLengths);
    this.scale(element);
  }

  scale(element) {
    element.lines.map((line) => {
      let scaledSegments = [];
      let segmentsResponse = [];
      line.segments.map((segment) => {
        segmentsResponse.push(segment);
        if (segment !== 8) {
          scaledSegments.push(segment * this.canvasConfig.scale);
        }else {
          scaledSegments.push(segment);
        }
      })
      line.segments = scaledSegments;
      line.segmentsOriginal = segmentsResponse;
      line.segmentsBeautify = this.beautifyElement(line);
    })
  }


  //Ready for delete no time now
  beautifyElement(line) {
    const segmentsBeautify = [];
    let hasCorner: boolean = false;
    line.segmentsOriginal.map((segment, index) => {
      if (segment == 8) {
        hasCorner = true;
        segmentsBeautify.slice(index);
        segmentsBeautify[index - 1] = segmentsBeautify[index - 1] + segment;
      } else {
        segmentsBeautify.push(segment);
        if (line.leftConnector && index === 0) {
          segmentsBeautify[0] = segmentsBeautify[0] + 8;
        }
      }
    })
    return segmentsBeautify
  }
}
