<div class="bcc-dimensions">
  <div class="row">
    <div class="col">
      <h2>{{'Dimensions' | translate}}</h2>
    </div>
  </div>
  <div class="row mb-3" style="justify-content: center">
    <div class="col canvas-wrapper" style="position: relative">
      <div class="actions"
           [style.width]="canvasConfig.width + 'px'"
           [ngClass]="{'show': element}">

<!--        NAMES-->

        <label class="element-height name"
               *ngIf="config.patternId != 5"
               [style.top]="
               newElementHeight > newElementHeightSecond ?
               newElementHeight < canvasConfig.height - canvasConfig.positionTop - 80 ?
               canvasConfig.positionTop + newElementHeight/2 + inputHeight/2 + 'px' : canvasConfig.height / 2 + 40 +  'px' :
               newElementHeightSecond + canvasConfig.positionTop - newElementHeight/2 + 20 + 'px'"
               [style.left]="newElementWidth < canvasConfig.width - 100 ? 'auto' : -16 + 'px'"
               [style.marginRight]="newElementWidth < canvasConfig.width - 100 ? newElementWidth + inputWidth/2 + 'px' : 0">
          <span class="line-name">L1</span>
        </label>

        <label class="element-height name"
               *ngIf="config.patternId == 5"
               [style.top]="
               newElementHeight > newElementHeightSecond ?
               newElementHeight < canvasConfig.height - canvasConfig.positionTop - 80 ?
               canvasConfig.positionTop + newElementHeight/2 + inputHeight/2 + 'px' : canvasConfig.height / 2 + 40 + 'px' :
               newElementHeightSecond + canvasConfig.positionTop - newElementHeight/2 + 20 + 'px'"
               [style.left]="0 + 'px'"
               [style.marginLeft]="-inputWidth/4 + 'px'">
          <span class="line-name">L1</span>
        </label>

        <label class="element-width name"
               *ngIf="config.patternId != 1 && config.patternId != 5 "
               [style.top]="
               newElementHeight > newElementHeightSecond ?
               newElementHeight < canvasConfig.height - 50 ?
               canvasConfig.positionTop + newElementHeight + inputHeight + 20 + 'px' : 'auto' :
               newElementHeightSecond < canvasConfig.height - 50 ? canvasConfig.positionTop + newElementHeightSecond + inputHeight + 20 + 'px' : 'auto' "
               [style.bottom]="newElementHeight > canvasConfig.height - 100 ? 0 : 'auto'">
          <span class="line-name">L2</span>
        </label>

        <label class="element-width name"
               *ngIf="config.patternId == 5"
               [style.top]="
               newElementHeight > newElementHeightSecond ?
               newElementHeight < canvasConfig.height - 50 ?
               canvasConfig.positionTop + newElementHeight + inputHeight + 20 + 'px' : 'auto' :
               newElementHeightSecond < canvasConfig.height - 50 ? canvasConfig.positionTop + newElementHeightSecond + inputHeight * 2 + 'px' : 'auto' "
               [style.left]="newElementWidthSecond + newElementWidth/2 + patternZCanvasCorrection - 10 + 'px'">
          <span class="line-name">L2</span>
        </label>

        <label class="element-height__second name"
               *ngIf="config.patternId == 4"
               [style.top]="
               newElementHeightSecond > newElementHeight ?
               newElementHeightSecond < canvasConfig.height - canvasConfig.positionTop - 80 ? canvasConfig.positionTop + newElementHeightSecond/2 + inputHeight/2 + 'px' : canvasConfig.height / 2 + 'px' :
               newElementHeight + canvasConfig.positionTop - newElementHeightSecond/2 + 20 + 'px'"
               [style.right]="newElementWidth < canvasConfig.width - 100 ? 'auto' : -20 + 'px'"
               [style.marginLeft]="newElementWidth < canvasConfig.width - 100 ? newElementWidth + inputWidth + 76 + 'px' : 0">
          <span class="line-name">L3</span>
        </label>

        <label class="element-width__second name"
               *ngIf="config.patternId == 5"
               [style.left]="newElementWidthSecond - 20 + 'px'"
               [style.top]="inputHeight + 'px'">
          <span class="line-name">L3</span>
        </label>

<!--        INPUTS-->

        <label class="element-height"
               *ngIf="config.patternId != 5"
               [style.top]="newElementHeight > newElementHeightSecond ? newElementHeight < canvasConfig.height - canvasConfig.positionTop - 80 ? canvasConfig.positionTop + newElementHeight/2 + inputHeight/2 + 'px' : canvasConfig.height / 2 + 'px' : newElementHeightSecond + canvasConfig.positionTop - newElementHeight/2 + 'px'"
               [style.left]="newElementWidth < canvasConfig.width - 100 ? 'auto' : -inputWidth + 'px'"
               [style.marginRight]="newElementWidth < canvasConfig.width - 100 ? newElementWidth + inputWidth + 60 + 'px' : 0">
            <input class="bcc-input" type="number" min="0" placeholder="mm" name="heightLength" [(ngModel)]="heightLength" [disabled]="suggestionsWidth.length > 0 || suggestionsHeightSecond.length > 0" (input)="getSuggestions($event, element.lines[0].lineId)">
            <span class="suggestions" [ngClass]="{'hide': suggestionsHeight?.length === 0, 'show': suggestionsHeight?.length > 0}">
              <span class="suggestion" *ngFor="let suggestion of suggestionsHeight" (click)="selectLength(suggestion, element.lines[0].lineId)">
                <span *ngIf="suggestion !== 0">{{suggestion}}</span>
              </span>
            </span>
        </label>

        <label class="element-height"
               *ngIf="config.patternId == 5"
               [style.top]="newElementHeight > newElementHeightSecond ? newElementHeight < canvasConfig.height - canvasConfig.positionTop - 80 ? canvasConfig.positionTop + newElementHeight/2 + inputHeight/2 + 'px' : canvasConfig.height / 2 + 'px' : newElementHeightSecond + canvasConfig.positionTop - newElementHeight/2 + 'px'"
               [style.left]="0 + 'px'"
               [style.marginLeft]="-inputWidth + 'px'">
          <input class="bcc-input" type="number" min="0" placeholder="mm" name="heightLength" [(ngModel)]="heightLength" [disabled]="suggestionsWidth.length > 0 || suggestionsWidthSecond.length > 0" (input)="getSuggestions($event, element.lines[1].lineId)">
          <span class="suggestions" [ngClass]="{'hide': suggestionsHeight?.length === 0, 'show': suggestionsHeight?.length > 0}">
              <span class="suggestion" *ngFor="let suggestion of suggestionsHeight" (click)="selectLength(suggestion, element.lines[1].lineId)">
                <span *ngIf="suggestion !== 0">{{suggestion}}</span>
              </span>
            </span>
        </label>

        <label class="element-width"
               *ngIf="config.patternId != 1 && config.patternId != 5 "
               [style.top]="newElementHeight > newElementHeightSecond ? newElementHeight < canvasConfig.height - 50 ? canvasConfig.positionTop + newElementHeight + inputHeight * 2 + 'px' : 'auto' : newElementHeightSecond < canvasConfig.height - 50 ? canvasConfig.positionTop + newElementHeightSecond + inputHeight * 2 + 'px' : 'auto' "
               [style.bottom]="newElementHeight > canvasConfig.height - 100 ? 0 : 'auto'">
            <input class="bcc-input" type="number" min="0" placeholder="mm" name="widthLength" [(ngModel)]="widthLength" [disabled]="suggestionsHeight.length > 0 || suggestionsHeightSecond.length > 0" (input)="getSuggestions($event, element.lines[1].lineId)">
            <span class="suggestions" [ngClass]="{'hide': suggestionsWidth?.length === 0, 'show': suggestionsWidth?.length > 0}">
            <span class="suggestion" *ngFor="let suggestion of suggestionsWidth" (click)="selectLength(suggestion, element.lines[1].lineId)">
              <span *ngIf="suggestion !== 0">{{suggestion}}</span>
            </span>
          </span>
        </label>

        <label class="element-width"
               *ngIf="config.patternId == 5"
               [style.top]="newElementHeight > newElementHeightSecond ? newElementHeight < canvasConfig.height - 50 ? canvasConfig.positionTop + newElementHeight + inputHeight * 2 + 'px' : 'auto' : newElementHeightSecond < canvasConfig.height - 50 ? canvasConfig.positionTop + newElementHeightSecond + inputHeight * 2 + 'px' : 'auto' "
               [style.left]="newElementWidthSecond + newElementWidth/2 - inputWidth/2 + patternZCanvasCorrection + 'px'">
          <input class="bcc-input" type="number" min="0" placeholder="mm" name="widthLength" [(ngModel)]="widthLength" [disabled]="suggestionsHeight.length > 0 || suggestionsWidthSecond.length > 0" (input)="getSuggestions($event, element.lines[2].lineId)">
          <span class="suggestions" [ngClass]="{'hide': suggestionsWidth?.length === 0, 'show': suggestionsWidth?.length > 0}">
            <span class="suggestion" *ngFor="let suggestion of suggestionsWidth" (click)="selectLength(suggestion, element.lines[2].lineId)">
              <span *ngIf="suggestion !== 0">{{suggestion}}</span>
            </span>
          </span>
        </label>

        <label class="element-height__second"
               *ngIf="config.patternId == 4"
               [style.top]="newElementHeightSecond > newElementHeight ? newElementHeightSecond < canvasConfig.height - canvasConfig.positionTop - 80 ? canvasConfig.positionTop + newElementHeightSecond/2 + inputHeight/2 + 'px' : canvasConfig.height / 2 + 'px' : newElementHeight + canvasConfig.positionTop - newElementHeightSecond/2 + 'px'"
               [style.right]="newElementWidth < canvasConfig.width - 100 ? 'auto' : -inputWidth + 'px'"
               [style.marginLeft]="newElementWidth < canvasConfig.width - 100 ? newElementWidth + inputWidth + 76 + 'px' : 0">
          <input class="bcc-input" type="number" min="0" placeholder="mm" name="heightLengthSecond" [(ngModel)]="heightLengthSecond" [disabled]="suggestionsHeight.length > 0 || suggestionsWidth.length > 0" (input)="getSuggestions($event, element.lines[2].lineId)">
          <span class="suggestions" [ngClass]="{'hide': suggestionsHeightSecond?.length === 0, 'show': suggestionsHeightSecond?.length > 0}">
            <span class="suggestion" *ngFor="let suggestion of suggestionsHeightSecond" (click)="selectLength(suggestion, element.lines[2].lineId)">
              <span *ngIf="suggestion !== 0">{{suggestion}}</span>
            </span>
          </span>
        </label>

        <label class="element-width__second"
               *ngIf="config.patternId == 5"
               [style.left]="newElementWidthSecond/2 - inputWidth/2 + patternZCanvasCorrection + 'px'">
          <input class="bcc-input" type="number" min="0" placeholder="mm" name="widthLengthSecond" [(ngModel)]="widthLengthSecond" [disabled]="suggestionsWidth.length > 0 || suggestionsHeight.length > 0" (input)="getSuggestions($event, element.lines[0].lineId)">
          <span class="suggestions" [ngClass]="{'hide': suggestionsWidthSecond?.length === 0, 'show': suggestionsWidthSecond?.length > 0}">
            <span class="suggestion" *ngFor="let suggestion of suggestionsWidthSecond" (click)="selectLength(suggestion, element.lines[0].lineId)">
              <span *ngIf="suggestion !== 0">{{suggestion}}</span>
            </span>
          </span>
        </label>

      </div>
      <canvas [ngClass]="{'show': element}" #canvas></canvas>
    </div>
  </div>
  <div class="row inputs-responsive">
    <div class="actions" [ngClass]="{'show': element}">
      <label class="element-height" *ngIf="config.patternId != 5">
        <span class="line-name mr-3">L1</span>
        <input class="bcc-input" type="number" min="0" placeholder="mm" name="heightLength" [(ngModel)]="heightLength" [disabled]="suggestionsWidth.length > 0 || suggestionsHeightSecond.length > 0" (input)="getSuggestions($event, element.lines[0].lineId)">
        <span class="suggestions" [ngClass]="{'hide': suggestionsHeight?.length === 0, 'show': suggestionsHeight?.length > 0}">
              <span class="suggestion" *ngFor="let suggestion of suggestionsHeight" (click)="selectLength(suggestion, element.lines[0].lineId)">
                <span *ngIf="suggestion !== 0">{{suggestion}}</span>
              </span>
            </span>
      </label>

      <label class="element-height" *ngIf="config.patternId == 5">
        <span class="line-name mr-3">L1</span>
        <input class="bcc-input" type="number" min="0" placeholder="mm" name="heightLength" [(ngModel)]="heightLength" [disabled]="suggestionsWidth.length > 0 || suggestionsWidthSecond.length > 0" (input)="getSuggestions($event, element.lines[1].lineId)">
        <span class="suggestions" [ngClass]="{'hide': suggestionsHeight?.length === 0, 'show': suggestionsHeight?.length > 0}">
              <span class="suggestion" *ngFor="let suggestion of suggestionsHeight" (click)="selectLength(suggestion, element.lines[1].lineId)">
                <span *ngIf="suggestion !== 0">{{suggestion}}</span>
              </span>
            </span>
      </label>

      <label class="element-width" *ngIf="config.patternId != 1 && config.patternId != 5 ">
        <span class="line-name mr-3">L2</span>
        <input class="bcc-input" type="number" min="0" placeholder="mm" name="widthLength" [(ngModel)]="widthLength" [disabled]="suggestionsHeight.length > 0 || suggestionsHeightSecond.length > 0" (input)="getSuggestions($event, element.lines[1].lineId)">
        <span class="suggestions" [ngClass]="{'hide': suggestionsWidth?.length === 0, 'show': suggestionsWidth?.length > 0}">
            <span class="suggestion" *ngFor="let suggestion of suggestionsWidth" (click)="selectLength(suggestion, element.lines[1].lineId)">
              <span *ngIf="suggestion !== 0">{{suggestion}}</span>
            </span>
          </span>
      </label>

      <label class="element-width" *ngIf="config.patternId == 5">
        <span class="line-name mr-3">L2</span>
        <input class="bcc-input" type="number" min="0" placeholder="mm" name="widthLength" [(ngModel)]="widthLength" [disabled]="suggestionsHeight.length > 0 || suggestionsWidthSecond.length > 0" (input)="getSuggestions($event, element.lines[2].lineId)">
        <span class="suggestions" [ngClass]="{'hide': suggestionsWidth?.length === 0, 'show': suggestionsWidth?.length > 0}">
            <span class="suggestion" *ngFor="let suggestion of suggestionsWidth" (click)="selectLength(suggestion, element.lines[2].lineId)">
              <span *ngIf="suggestion !== 0">{{suggestion}}</span>
            </span>
          </span>
      </label>

      <label class="element-height__second" *ngIf="config.patternId == 4">
        <span class="line-name mr-3">L3</span>
        <input class="bcc-input" type="number" min="0" placeholder="mm" name="heightLengthSecond" [(ngModel)]="heightLengthSecond" [disabled]="suggestionsHeight.length > 0 || suggestionsWidth.length > 0" (input)="getSuggestions($event, element.lines[2].lineId)">
        <span class="suggestions" [ngClass]="{'hide': suggestionsHeightSecond?.length === 0, 'show': suggestionsHeightSecond?.length > 0}">
            <span class="suggestion" *ngFor="let suggestion of suggestionsHeightSecond" (click)="selectLength(suggestion, element.lines[2].lineId)">
              <span *ngIf="suggestion !== 0">{{suggestion}}</span>
            </span>
          </span>
      </label>

      <label class="element-width__second" *ngIf="config.patternId == 5">
        <span class="line-name mr-3">L3</span>
        <input class="bcc-input" type="number" min="0" placeholder="mm" name="widthLengthSecond" [(ngModel)]="widthLengthSecond" [disabled]="suggestionsWidth.length > 0 || suggestionsHeight.length > 0" (input)="getSuggestions($event, element.lines[0].lineId)">
        <span class="suggestions" [ngClass]="{'hide': suggestionsWidthSecond?.length === 0, 'show': suggestionsWidthSecond?.length > 0}">
            <span class="suggestion" *ngFor="let suggestion of suggestionsWidthSecond" (click)="selectLength(suggestion, element.lines[0].lineId)">
              <span *ngIf="suggestion !== 0">{{suggestion}}</span>
            </span>
          </span>
      </label>
    </div>
  </div>
  <div class="dimension-buttons row mb-3 mt-5 d-flex justify-content-center" *ngIf="element">
    <div class="col mb-3 d-flex justify-content-end">
      <button class="bcc-button" (click)="showResetConfirmation = true" [disabled]="!widthLength && !heightLength && !heightLengthSecond && !widthLengthSecond">{{'Reset' | translate}}</button>
    </div>
    <div class="col mb-3 d-flex justify-content-start">
      <button class="bcc-button" [disabled]="buttonDisabled" (click)="postDimensions()">{{'Calculate' | translate}}</button>
    </div>
  </div>
  <div class="bcc-loading" *ngIf="showLoading"></div>
</div>

<div class="bcc-reset-confirmation__wrapper" [class.show]="showResetConfirmation" (click)="showResetConfirmation = false">
  <div class="bcc-reset-confirmation" (click)="$event.stopPropagation();">
    <h3>{{'Reset dimensions' | translate}}</h3>
    <p>{{'Are you sure you want to reset dimensions?' | translate}}</p>
    <div class="actions">
      <a class="cancel" (click)="showResetConfirmation = false">{{'Cancel' | translate}}</a>
      <a class="reset" (click)="resetCanvas()">{{'Reset' | translate}}</a>
    </div>
  </div>
</div>

<div class="bcc-reset-confirmation__wrapper" [class.show]="showDimensionAlert" (click)="showDimensionAlert = false">
  <div class="bcc-reset-confirmation" (click)="$event.stopPropagation();">
    <h3>{{'Notification' | translate}}</h3>
    <p>{{'Dimension must be less than' | translate}} {{dimensionLimit}}.</p>
    <div class="actions">
      <a class="cancel" (click)="showDimensionAlert = false">{{'Cancel' | translate}}</a>
    </div>
  </div>
</div>
