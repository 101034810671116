<div class="bcc-root">
    <header class="bcc-header">
      <div class="container">
        <div class="row">
          <div class="col-md-11 col-7">
            <a class="bcc-header__action" href="https://buck.lighting">{{'Back to website' | translate}}</a>
          </div>
          <div class="col-md-1 col-5 d-flex justify-content-end bcc-language-toggle">
            <div class="form-group">
              <label>
                <ng-select
                  bindLabel="name"
                  bindValue="value"
                  [(ngModel)]="selectedLanguage"
                  [items]="languages"
                  [virtualScroll]="true"
                  [searchable]="false"
                  (ngModelChange)="changeLanguage()">
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    {{item.name}}
                  </ng-template>
                </ng-select>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="bcc-header__title" [routerLink]="['/']">
              <img src="assets/images/buck_logo.svg" alt="">
              <h1>{{'Configurator' | translate}}</h1>
            </div>
            <div class="bcc-decoration-line"></div>
          </div>
        </div>
        <div class="row">
          <div class="col header-info">
            <span>BUCK {{'configurator allows you to create custom solutions for your lighting, based on the configurable products we offer.' | translate}}</span>
          </div>
        </div>
      </div>
    </header>
    <nav class="row">
      <div class="col-12 p-0 bcc-navigation__content" [class.show]="showResponsiveNavigation">
        <div class="bcc-navigation" [perfectScrollbar]="perfectScrollConfig" #navigation>
          <div class="bcc-navigation__toggler-close" *ngIf="showResponsiveNavigation" (click)="toggleNavigation()"><span class="close"></span></div>
          <ul>
            <li class="done bcc-not-clickable title">
              <h2>{{'Design' | translate}}</h2>
            </li>
            <li
              class="not-active"
              [ngClass]="{
                  'done': queryParams.installationTypeId,
                  'active': !queryParams.installationTypeId
                  }"
              (click)="resetQueryParams('installationType')">
              01. {{'Installation type' | translate}}
              <p [class.show]="queryParams.installationTypeId" *ngIf="configuratorValues.installationType">{{configuratorValues.installationType['name_' + selectedLanguage]}}</p>
            </li>
            <li
              class="not-active"
              [ngClass]="{
              'done': queryParams.contourWidth,
              'active': queryParams.installationTypeId && !queryParams.contourWidth
            }"
              (click)="resetQueryParams('contourWidth')">
              02. {{'Contour width' | translate}}
              <p [class.show]="queryParams.contourWidth">{{queryParams.contourWidth}}MM</p>
            </li>
            <li
              class="not-active"
              [ngClass]="{
              'done': queryParams.productId,
              'active': queryParams.contourWidth && !queryParams.productId
            }"
              (click)="resetQueryParams('product')">
              03. {{'Product' | translate}}
              <p [class.show]="queryParams.productId">{{queryParams.productName}}</p>
            </li>
            <li
              class="not-active"
              [ngClass]="{
              'done': queryParams.patternId,
              'active': queryParams.productId && !queryParams.patternId
            }"
              (click)="resetQueryParams('patternId')">
              04. {{'Contour shape' | translate}}
              <p [class.show]="queryParams.patternId" *ngIf="configuratorValues.pattern">{{configuratorValues.pattern.name | translate}}</p>
            </li>
            <li
              class="not-active dimensions"
              [ngClass]="{
              'done': queryParams.dimensions,
              'active': queryParams.patternId && !queryParams.dimensions
            }">
              <span class="list-item" (click)="resetQueryParams('dimensions')">05. {{'Dimensions' | translate}}</span>
              <p [class.show]="configuratorValues.dimensions" [class.hide]="hideDimensions" [perfectScrollbar]="hideDimensions ? {suppressScrollX: true, suppressScrollY: true} : perfectScrollConfig">
                <span class="dimension-toggle-icon not-responsive" [class.open]="!hideDimensions" (click)="toggleDimensions($event)"><img src="assets/images/arrow-back.svg" alt=""></span>
                <span style="padding-right: 12px" (click)="$event.stopPropagation(); $event.preventDefault(); toggleDimensions($event)"><span *ngIf="configuratorValues.dimensions?.total.corner">{{'Corner' | translate}}: </span>{{configuratorValues.dimensions?.total.corner}}</span>
                <br>
                <span (click)="$event.stopPropagation();$event.preventDefault();"><span *ngIf="configuratorValues.dimensions?.total.lid">{{'Lid' | translate}}: </span>{{configuratorValues.dimensions?.total.lid}}</span>
                <span style="display: block; cursor: pointer" *ngFor="let line of configuratorValues.dimensions?.total?.lines; let i = index" (click)="toggleDimensionsInfo($event, i)">
                      L{{i + 1}} <span class="dimension-toggle-icon" [class.open]="selectDimensionIndex.includes(i)"><img src="assets/images/arrow-back.svg" alt=""></span>
                      <span class="dimension-info-wrapper" [class.show]="selectDimensionIndex.includes(i)">
                        <span class="dimension-info-element" *ngFor="let val of line">{{val}}</span>
                      </span>
                    </span>
              </p>
            </li>
            <li  class="bcc-not-clickable title" [ngClass]="{ 'done': route.url.includes('result') }">
              <h2>{{'Result' | translate}}</h2>
            </li>
            <li class="not-active title small" [ngClass]="{ 'active': route.url.includes('result') }">{{'Technical specification \nof contours and segments' | translate}}</li>
          </ul>
        </div>
      </div>
    </nav>
    <main class="bcc-main" [perfectScrollbar]="perfectScrollConfig" [class.matrica] = "(queryParams.productId && !queryParams.patternId) || (queryParams.patternId && !queryParams.dimensions && !route.url.includes('result'))" #mainContent>
      <div class="container" [class.matrica] = "(queryParams.productId && !queryParams.patternId) || (queryParams.patternId && !queryParams.dimensions && !route.url.includes('result'))">
        <div class="row">
          <div class="col-12 pb-3 bcc-main__content">
            <bcc-built-in-variant [selectedLanguage]="selectedLanguage" *ngIf="!queryParams.installationTypeId && !route.url.includes('result')"></bcc-built-in-variant>
            <bcc-contour-width *ngIf="queryParams.installationTypeId && !queryParams.contourWidth"></bcc-contour-width>
            <bcc-product *ngIf="queryParams.contourWidth && !queryParams.productId"></bcc-product>
            <bcc-contour-shape *ngIf="queryParams.productId && !queryParams.patternId"></bcc-contour-shape>
            <bcc-dimensions *ngIf="queryParams.patternId && !queryParams.dimensions && !route.url.includes('result')"></bcc-dimensions>
            <router-outlet></router-outlet>
          </div>
          <div class="bcc-navigation__toggler" (click)="toggleNavigation()"><span class="open"></span></div>
        </div>
      </div>
    </main>
  </div>

