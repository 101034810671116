import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ConfiguratorService } from "../../_services/configurator.service";
import { Config } from "../../_classes/config";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'bcc-product',
  templateUrl: './product.component.html'
})
export class ProductComponent implements OnInit {
  config: Config;

  products: any;
  selectedProduct: any;

  showFilters: boolean = false;

  filters = {
    dimmable: null,
    diffuser: null,
    color_temperature: null,
    efficiency: null,
    color: null
  }

  diffusers: string[] = [];
  productColors: any[] = [];

  apiRoot = environment.apiRoot
  showLoading: boolean = false;

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private configuratorService: ConfiguratorService
  ) { }

  ngOnInit() {
    this.getQueryParams();
    this.getProducts();
  }

  getQueryParams() {
    this.router.queryParams.subscribe((params: Config) => {
      this.config = params
    })
  }

  getProducts() {
    this.showLoading = true;
    const params = {
      installation_type: this.config.installationTypeId,
      width: this.config.contourWidth,
      group_by: 'group_name'
    }
    this.configuratorService.getProducts(params)
      .subscribe(
        (products) => {
          this.products = products.data;
          if (this.products.length === 1) { this.selectProduct(this.products[0]) }
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      )
  }

  selectProduct(product) {
    this.selectedProduct = product;
    this.showFilters = true;
    this.getDiffusers();
  }

  getDiffusers() {
    this.showLoading = true;
    this.diffusers = [];
    const params = {
      installation_type: this.config.installationTypeId,
      width: this.config.contourWidth,
      group_name: this.selectedProduct.group_name
    }
    this.configuratorService.getProducts(params)
      .subscribe(
        (products) => {
          products.data.map((product) => {
            if (product.diffuser) { this.diffusers.push(product.diffuser); }
          })
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      )
  }

  getColors() {
    if (!this.filters.efficiency || !this.filters.color_temperature || !this.filters.dimmable || (this.diffusers.length > 0 && !this.filters.diffuser)) { return; }
    this.showLoading = true;
    this.productColors = [];
    this.filters.color = null;
    const params = {
      installation_type: this.config.installationTypeId,
      width: this.config.contourWidth,
      group_name: this.selectedProduct.group_name,
      ...this.filters
    }
    this.configuratorService.getProductColors(params)
      .subscribe(
        (colors) => {
          this.productColors = colors.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      )
  }

  resetColors() {
    if (this.productColors.length > 0) {
      this.productColors = [];
    }
  }

  next() {
    this.showLoading = true;
    const params = {
      installation_type: this.config.installationTypeId,
      width: this.config.contourWidth,
      diffuser: this.filters.diffuser,
      group_name: this.selectedProduct.group_name,
      color: this.filters.color,
    }
    this.configuratorService.setFilters(this.filters);
    this.configuratorService.getProducts(params)
      .subscribe(
        (product) => {
          const productResponse = product.data[0].product;
          this.route.navigate(['/'], {queryParams: { productId: productResponse.id, productName: productResponse.name}, queryParamsHandling: "merge" })
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      )
  }

}
