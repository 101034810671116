<div class="bcc-result">
  <div class="row mb-3">
    <div class="col-md-6">
      <h2>{{'Technical specification of contours and segments' | translate}}</h2>
    </div>
    <div class="col-md-6 d-flex justify-content-md-end actions">
      <button class="bcc-button" style="height: 52px" (click)="showContactModal = true">{{'Contact us' | translate}}</button>
      <button class="bcc-button" style="height: 52px" (click)="downloadReport()">{{'Print' | translate}}</button>
      <button class="bcc-button" style="height: 52px" (click)="downloadDXF()">{{'DXF' | translate}}</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="bcc-report" [innerHTML]="report"></div>
    </div>
  </div>
  <div class="bcc-loading" *ngIf="showLoading"></div>
</div>

<div class="bcc-reset-confirmation__wrapper" [class.show]="showContactModal" (click)="showContactModal = false">
  <div class="bcc-reset-confirmation" (click)="$event.stopPropagation();">
    <h3>{{'Contact us' | translate}}</h3>
    <label style="width: 100%">
      <input class="bcc-input fullWidth" type="email" [placeholder]="'Email'" [(ngModel)]="contactEmail" />
    </label>
    <button class="bcc-button small" style="float: right;" (click)="sendToEmail()">{{'Send' | translate}}</button>
  </div>
</div>
