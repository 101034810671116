import { Component, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { ActivatedRoute, Router } from "@angular/router";
import { Config } from "../_classes/config";
import { ConfiguratorService } from "../_services/configurator.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html'
})
export class ConfiguratorComponent implements OnInit {

  @ViewChild('mainContent') mainContent;
  @ViewChild('navigation') navigation;

  perfectScrollConfig: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
  };

  queryParams: Config;

  configuratorValues: {
    installationType: any,
    contourWidth: any,
    product: any,
    pattern: any,
    dimensions: {
      selectedElements: any,
      total: {
        corner: any;
        lid: any;
        lines: any[]
      }
    }
  } = {
    installationType: null,
    contourWidth: null,
    product: null,
    pattern: null,
    dimensions: null
  }
  showResponsiveNavigation: boolean = false;
  selectedLanguage: string;
  languages: any[] = [
    {
      name: 'RS',
      value: 'rs'
    },
    {
      name: 'EN',
      value: 'en'
    },
    {
      name: 'DE',
      value: 'de'
    },
    {
      name: 'RU',
      value: 'ru'
    },
  ];
  showLanguages: boolean = false;

  selectDimensionIndex: any = [0];

  hideDimensions: boolean = true;

  constructor(
    private router: ActivatedRoute,
    public route: Router,
    private configuratorService: ConfiguratorService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getSelectedLanguage();
    this.getQueryParams();
  }

  getSelectedLanguage() {
    this.selectedLanguage = JSON.parse(localStorage.getItem('bcc-language'));
  }

  getQueryParams() {
    this.router.queryParams.subscribe((params: Config) => {
      this.queryParams = params;
      if (params.installationTypeId && !this.configuratorValues.installationType) { this.getInstallationType(params.installationTypeId) } else if (!params.installationTypeId && this.configuratorValues.installationType) {this.configuratorValues.installationType = null;}
      if (params.contourWidth && !this.configuratorValues.contourWidth) { this.configuratorValues.contourWidth = params.contourWidth }else if (!params.contourWidth && this.configuratorValues.contourWidth) { this.configuratorValues.contourWidth = null; }
      if (params.productId && !this.configuratorValues.product) { this.getProduct(params.productId) }else if (!params.productId && this.configuratorValues.product) { this.configuratorValues.product = null; this.configuratorService.clearFilters(); }
      if (params.patternId && !this.configuratorValues.pattern) { this.getPattern(params.patternId)}else if (!params.patternId && this.configuratorValues.pattern) { this.configuratorValues.pattern = null }
      if (!params.dimensions) { this.hideDimensions = true; this.configuratorService.clearSelectedDimensions(); this.configuratorValues.dimensions = this.configuratorService.selectedDimensions; }else { this.configuratorService.getSelectedDimensions(); this.configuratorValues.dimensions = this.configuratorService.selectedDimensions; }
      if (this.mainContent) { this.mainContent.nativeElement.scrollTop = 0; }
    })
  }

  getInstallationType(installationTypeId) {
    this.configuratorService.getInstallationType(installationTypeId)
      .subscribe(
        (installationType) => {
          this.configuratorValues.installationType = installationType.data;
        },
        () => {}
      )
  }

  getProduct(productId) {
    this.configuratorService.getProduct(productId)
      .subscribe(
        (product) => {
          this.configuratorValues.product = product;
        },
        () => {}
      )
  }

  getPattern(patternId) {
    this.configuratorService.getPattern(patternId)
      .subscribe(
        (pattern) => {
          this.configuratorValues.pattern = pattern.data;
        },
        () => {}
      )
  }

  resetQueryParams(param) {
    let params: any = {};
    if (param === 'installationType') { params = {}; }
    if (param === 'contourWidth') {  params = { installationTypeId: this.queryParams.installationTypeId }; }
    if (param === 'product') { params = { installationTypeId: this.queryParams.installationTypeId, contourWidth: this.queryParams.contourWidth } }
    if (param === 'patternId') { params = { installationTypeId: this.queryParams.installationTypeId, contourWidth: this.queryParams.contourWidth, productId: this.queryParams.productId, productName: this.queryParams.productName } }
    if (param === 'dimensions') { params = { installationTypeId: this.queryParams.installationTypeId, contourWidth: this.queryParams.contourWidth, productId: this.queryParams.productId, productName: this.queryParams.productName, patternId: this.queryParams.patternId } }
    this.route.navigate(['/'], {queryParams: params})
    this.toggleNavigation();
  }

  toggleNavigation() {
    this.navigation.nativeElement.scrollTop = 0;
    this.showResponsiveNavigation = !this.showResponsiveNavigation;
  }

  changeLanguage() {
    this.translate.setDefaultLang(this.selectedLanguage);
  }

  toggleDimensionsInfo(event, index) {
    event.stopPropagation();
    if (this.selectDimensionIndex.includes(index)) {
      this.selectDimensionIndex.splice(this.selectDimensionIndex.indexOf(index) , 1);
    } else {
      this.selectDimensionIndex.push(index);
    }
  }

  toggleDimensions(event) {
    event.stopPropagation();
    this.hideDimensions = !this.hideDimensions;
  }
}
