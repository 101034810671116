import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { Config } from "../_classes/config";
import { ConfiguratorService } from "../_services/configurator.service";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html'
})
export class ResultComponent implements OnInit, OnDestroy {
  showLoading: boolean = false;

  config: Config;
  element: any;

  report: string;
  reportProductTag: string;

  selectedProductFilters: any;
  canvasUrl: any;
  showContactModal: boolean = false;
  contactEmail: string;
  selectedLanguage: string;
  subscription: Subscription;

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private location: Location,
    private configuratorService: ConfiguratorService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (!this.configuratorService.selectedDimensions) {
      this.location.back();
    } else {
      this.getSelectedLanguage();
      this.getQueryParams();
      this.getSelectedFilters();
      this.getCanvasUrl();
      this.getElement();
      this.getReport();
      this.subscription = this.translate.onDefaultLangChange.subscribe((translate) => {
        this.selectedLanguage = translate.lang;
        this.getReport();
      })
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getQueryParams() {
    this.router.queryParams.subscribe((params: Config) => {
      this.config = params
    })
  }

  getElement() {
    if (this.configuratorService.selectedDimensions) {
      this.element = this.configuratorService.selectedDimensions
    }else {
      this.element = JSON.parse(localStorage.getItem('elementDimensions'));
    }
  }

  getReport() {
    this.showLoading = true;
    const params = {
      element: this.element,
      canvasUrl: this.canvasUrl,
      filters: this.selectedProductFilters,
      language: this.selectedLanguage
    }
    this.configuratorService.getReport(this.config.patternId,this.config.productId, params)
      .subscribe(
        (report) => {
          this.report = report.data.tender_description;
          this.reportProductTag = report.data.product_tag;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      )
  }

  getSelectedFilters() {
    this.selectedProductFilters = this.configuratorService.getFilters();
  }

  getCanvasUrl() {
    this.canvasUrl = JSON.parse(localStorage.getItem('canvasUrl'));
  }

  getSelectedLanguage() {
    this.selectedLanguage = JSON.parse(localStorage.getItem('bcc-language'));
  }

  downloadReport() {
    const params = {
      element: this.element,
      canvasUrl: this.canvasUrl,
      filters: this.selectedProductFilters,
      language: this.selectedLanguage
    }
    this.configuratorService.downloadReport(this.config.patternId,this.config.productId, params)
      .subscribe(
        (response) => {
          window.open(response, '_blank');
        },
        () => {}
      )
  }

  downloadDXF() {
    const params = {
      element: this.element
    }
    this.configuratorService.downloadDXF(this.config.patternId,this.config.productId, params)
      .subscribe(
        (response) => {
          window.open(response, '_blank');
        },
        () => {}
      )
  }

  sendToEmail() {
    const params = {
      email: this.contactEmail,
      product_tag: this.reportProductTag,
    }
    this.configuratorService.sendEmail(params)
      .subscribe(
        () => {
          this.contactEmail = null;
          this.showContactModal = false;
        },
        () => {},
      );
  }
}
