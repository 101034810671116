import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class ConfiguratorService {

  public selectedDimensions: any;

  constructor(
    private apiService: ApiService
  ) { }

  getInstallationTypes(params?: any) {
    return this.apiService.get(`configurator/installation_types`, params);
  }

  getInstallationType(id:number, params?: any) {
    return this.apiService.get(`configurator/installation_types/${id}`, params);
  }

  getProducts(params?: any) {
    return this.apiService.get(`configurator/products`, params);
  }

  getProduct(productId: any, params?: any) {
    return this.apiService.get(`configurator/products/${productId}`, params);
  }

  getDimensions(productId: number, patternId: number, params?: any) {
    return this.apiService.get(`configurator/patterns/${patternId}/products/${productId}`, params);
  }

  getDimensionSuggestions(productId: number, patternId: number, params?: any) {
    return this.apiService.post(`configurator/patterns/${patternId}/products/${productId}/suggestion`, params);
  }

  getPatterns(params?: any) {
    return this.apiService.get(`configurator/patterns`, params);
  }

  getPattern(id: number, params?: any) {
    return this.apiService.get(`configurator/patterns/${id}`, params);
  }

  getReport(patternId: any, productId: any, params?: any) {
    return this.apiService.post(`configurator/patterns/${patternId}/products/${productId}/report`, params);
  }

  downloadReport(patternId: any, productId: any, params?: any) {
    return this.apiService.post(`configurator/patterns/${patternId}/products/${productId}/download_report`, params);
  }

  setNewDimensions(element) {
    localStorage.setItem('elementDimensions', JSON.stringify(element));
    this.selectedDimensions = element;
  }

  getSelectedDimensions() {
    if (localStorage.getItem('elementDimensions')) {
      this.selectedDimensions = JSON.parse(localStorage.getItem('elementDimensions'));
    }
  }

  clearSelectedDimensions() {
    this.selectedDimensions = null;
    if (localStorage.getItem('elementDimensions')) { localStorage.removeItem('elementDimensions'); }
  }

  setFilters(filters) {
    localStorage.setItem('productFilters', JSON.stringify(filters));
  }

  getFilters() {
    if (localStorage.getItem('productFilters')) {
      return  JSON.parse(localStorage.getItem('productFilters'));
    }
  }

  clearFilters() {
    if (localStorage.getItem('productFilters')) { localStorage.removeItem('productFilters'); }
  }

  setCanvasUrl(dataUrl) {
    localStorage.setItem('canvasUrl', JSON.stringify(dataUrl));
  }

  clearCanvasUrl() {
    if (localStorage.getItem('canvasUrl')) { localStorage.removeItem('canvasUrl'); }
  }

  downloadDXF(patternId: any, productId: any, params?: any) {
    return this.apiService.post(`configurator/patterns/${patternId}/products/${productId}/generate_dxf`, params);
  }

  getProductColors(params: any = {}) {
    return this.apiService.get(`configurator/products/colors`, params);
  }

  sendEmail(params: any = {}) {
    return this.apiService.post(`configurator/contact`, params);
  }
}
