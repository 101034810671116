import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCTooltipDirective } from './tooltip.directive';



@NgModule({
  declarations: [
    DCTooltipDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DCTooltipDirective
  ]
})
export class DCTooltipModule { }
