<div class="bcc-product">
  <div class="row">
    <div class="col">
      <h2>{{'Product' | translate}}</h2>
    </div>
  </div>
  <!--    PRODUCTS-->
  <div class="row" *ngIf="!showFilters">
    <div class="col-12 col-lg-4 col-sm-6" *ngFor="let product of products">
      <div class="bcc-card large product-card" (click)="selectProduct(product)">
        <div class="card-image" [style.backgroundImage]="'url('+ apiRoot + 'content/main_images/' + product.product.main_image +')'"></div>
        <span>{{product.group_name}}</span>
      </div>
    </div>
  </div>
  <div class="row" style="margin-bottom: 8px" *ngIf="showFilters && selectedProduct">
    <div class="col-12">
      <div class="row ml-1">
        <!--    FILTERS-->
        <div class="col-lg-2 col-md-3 mt-3">
          <div class="bcc-product__filters">
            <h2>{{'Driver' | translate}}</h2>
            <label dcTooltip [placement]="'bottom'" [regulationMilestone]="{description: 'ON/OFF Switch' | translate}">
              <input type="radio" name="dimmer" class="bu-input-radio" [value]="'ON/OFF'" [(ngModel)]="filters.dimmable" (ngModelChange)="getColors()">
              <span>{{'ON/OFF' | translate}}</span>
            </label>
            <label dcTooltip [placement]="'bottom'" [regulationMilestone]="{description: 'Digital Addressable Lighting Interface' | translate}">
              <input type="radio" name="dimmer" class="bu-input-radio" [value]="'DALI'" [(ngModel)]="filters.dimmable" (ngModelChange)="getColors()">
              <span>{{'DALI' | translate}}</span>
            </label>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 mt-3" *ngIf="diffusers.length > 0">
          <div class="bcc-product__filters">
            <h2>{{'Diffuser' | translate}}</h2>
            <label *ngFor="let diffuser of diffusers" dcTooltip [placement]="'bottom'" [regulationMilestone]="{description: diffuser === 'DO' ? ('Opal diffuser' | translate) : ('Highly transparent micro prismatic diffuser' | translate)}">
              <input type="radio" name="diffuser" class="bu-input-radio" [value]="diffuser" [(ngModel)]="filters.diffuser" (ngModelChange)="getColors()">
              <span>{{diffuser}}</span>
            </label>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 mt-3">
          <div class="bcc-product__filters">
            <h2>{{'Lumen package' | translate}}</h2>
            <div class="bcc-lumen-package">
              <label dcTooltip [placement]="'bottom'" [regulationMilestone]="{description: 'High efficiency' | translate}">
                <input type="radio" name="efficiency" class="bu-input-radio" [value]="'HE'" [(ngModel)]="filters.efficiency" (ngModelChange)="getColors()">
                <span>HE</span>
              </label>
              <label class="bcc-lumen-package__second-element" dcTooltip [placement]="'bottom'" [regulationMilestone]="{description: 'High output' | translate}">
                <input type="radio" name="efficiency" class="bu-input-radio" [value]="'HO'" [(ngModel)]="filters.efficiency" (ngModelChange)="getColors()">
                <span>HO</span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 mt-3" *ngIf="filters.efficiency">
          <div class="bcc-product__filters">
            <h2>{{'Color temperature' | translate}}</h2>
            <div class="bcc-lumen-package">
              <label dcTooltip [placement]="'bottom'" [regulationMilestone]="{description: 'Warm' | translate}">
                <input type="radio" name="color_temperature" class="bu-input-radio" [value]="'3k'" [(ngModel)]="filters.color_temperature" (ngModelChange)="getColors()">
                <span>3000k</span>
              </label>
              <label class="bcc-lumen-package__second-element" dcTooltip [placement]="'bottom'" [regulationMilestone]="{description: 'Cool' | translate}">
                <input type="radio" name="color_temperature" class="bu-input-radio" [value]="'4k'" [(ngModel)]="filters.color_temperature" (ngModelChange)="getColors()">
                <span>4000k</span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 mt-3" *ngIf="productColors.length > 0">
          <div class="bcc-product__filters">
            <h2>{{'Luminaire color' | translate}}</h2>
            <div class="color-wrapper">
              <label *ngFor="let color of productColors" dcTooltip [placement]="'bottom'" [regulationMilestone]="{description: color.name | translate}">
                <input type="radio" name="color" class="bu-input-radio" [value]="color.id" [(ngModel)]="filters.color">
                <img src="{{apiRoot + 'content/symbols/' + color.image}}" alt="">
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row ml-1 filters-info-wrapper" *ngIf="productColors.length === 0 && !showLoading">
    <div class="col">
      <p class="filters-info"><sup style="color: #c51d63">*</sup> {{ "Fill out all the options to get the color select option." | translate }}</p>
    </div>
  </div>
  <div class="row" *ngIf="selectedProduct">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="bcc-card large product-card">
            <div class="card-image" [style.backgroundImage]="'url('+ apiRoot + 'content/main_images/' + selectedProduct.product.main_image +')'"></div>
            <span>{{selectedProduct.group_name}}</span>
          </div>
        </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="col-md-12 d-flex justify-content-center mt-3" *ngIf="showFilters">
        <button class="bcc-button" (click)="next()" [disabled]="(diffusers.length > 0 && !filters.diffuser) || filters.dimmable === null || !filters.efficiency || !filters.color">{{'Next' | translate}}</button>
      </div>
    </div>
  </div>
  <div class="bcc-loading" *ngIf="showLoading"></div>
</div>
