import { Component, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  selectedLanguage: string = 'rs';
  constructor(
    private translate: TranslateService,
    private titleService: Title
  ) {
    translate.onDefaultLangChange.subscribe((translate) => {
      this.titleService.setTitle(this.translate.instant('Buck configurator'));
      this.selectedLanguage = translate.lang;
      localStorage.setItem('bcc-language', JSON.stringify(this.selectedLanguage));
    })
    if (localStorage.getItem('bcc-language')) {
      if (localStorage.getItem('bcc-language').length === 2) { localStorage.setItem('bcc-language', JSON.stringify(localStorage.getItem('bcc-language'))); }
      this.selectedLanguage = JSON.parse(localStorage.getItem('bcc-language'));
    } else {
      localStorage.setItem('bcc-language', JSON.stringify(this.selectedLanguage));
    }
    translate.setDefaultLang(this.selectedLanguage);
    translate.use('en');
  }


  ngOnInit() {}
}
