<div class="bcc-built-in">
  <div class="row">
    <div class="col">
      <h2>{{'Installation type' | translate}}</h2>
    </div>
  </div>
  <div class="row" style="justify-content: space-between">
    <div class="col-lg-2 col-md-3 col-sm-6" *ngFor="let installationType of installationTypes">
      <div class="bcc-card other-card"
           [routerLink]="['/']"
           [queryParams]="{installationTypeId: installationType.id}"
           [queryParamsHandling]="'merge'"
           dcTooltip
           [placement]="'top'"
           [regulationMilestone]="{description: installationType['name_' + selectedLanguage]}"
           [offsetActive]="true"
      >
        <div class="card-image full"
             [style.backgroundImage]="'url('+ apiRoot +'content/installation_methods/'+ installationType.image_url +')'"></div>
      </div>
    </div>
  </div>
  <div class="bcc-loading" *ngIf="showLoading"></div>
</div>
