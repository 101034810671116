import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ConfiguratorService } from "../../_services/configurator.service";
import { Config } from "../../_classes/config";

@Component({
  selector: 'bcc-contour-width',
  templateUrl: './contour-width.component.html'
})
export class ContourWidthComponent implements OnInit {

  config: Config;
  widths: any[] = [];

  showLoading: boolean = false;

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private configuratorService: ConfiguratorService
  ) { }

  ngOnInit() {
    this.getQueryParams();
    this.getWidths();
  }

  getQueryParams() {
    this.router.queryParams.subscribe((params: Config) => {
      this.config = params
    })
  }

  getWidths() {
    this.showLoading = true;
    this.configuratorService.getInstallationType(this.config.installationTypeId)
      .subscribe(
        (response) => {
          this.widths = response.data.widths;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      )
  }

}
