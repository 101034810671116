import { Component, OnInit } from '@angular/core';
import { ConfiguratorService } from "../../_services/configurator.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'bcc-contour-shape',
  templateUrl: './contour-shape.component.html'
})
export class ContourShapeComponent implements OnInit {

  apiRoot = environment.apiRoot;
  showLoading: boolean = false;

  patterns: any[] = [];

  constructor(
    private configuratorService: ConfiguratorService
  ) { }

  ngOnInit() {
    this.getPatterns();
  }

  getPatterns() {
    this.showLoading = true;
    this.configuratorService.getPatterns()
      .subscribe(
        (patterns) => {
          this.patterns = patterns.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      );
  }
}
