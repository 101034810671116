import { Component, Input, OnInit } from '@angular/core';
import { ConfiguratorService } from '../../_services/configurator.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'bcc-built-in-variant',
  templateUrl: './built-in-variant.component.html'
})
export class BuiltInVariantComponent implements OnInit {

  @Input() selectedLanguage: string;

  showLoading = false;
  installationTypes: any[] = [];
  apiRoot = environment.apiRoot;
  constructor(
    private configuratorService: ConfiguratorService
  ) { }

  ngOnInit() {
    this.getInstallationTypes();
  }

  getInstallationTypes() {
    this.showLoading = true;
    this.configuratorService.getInstallationTypes().subscribe(
      (installationTypes) => {
        this.installationTypes = installationTypes.data;
        this.showLoading = false;
      },
      () => {
        this.showLoading = false;
      }
    );
  }
}
