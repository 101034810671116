<div class="bcc-pattern">
  <div class="row">
    <div class="col">
      <h2>{{'Contour shape' | translate}}</h2>
    </div>
  </div>
  <div class="row justify-content-between">
    <div class="col-12 col-lg-2 col-md-3 col-sm-6" *ngFor="let pattern of patterns">
      <div class="bcc-card pattern full-on-res"
           [routerLink]="['/']"
           [queryParams]="{patternId: pattern.id}"
           [queryParamsHandling]="'merge'">
        <div class="card-image"
             [style.backgroundImage]="'url('+ apiRoot +'content/patterns/'+ pattern.image_url +')'"
             [ngClass]="{
             'line': pattern.name === 'I shape',
             'l-contour': pattern.name === 'L shape',
             'rectangular': pattern.name === 'O shape',
             'partially-closed': pattern.name === 'U shape',
             'double-broken': pattern.name === 'Z shape'
             }"></div>
        <span>{{pattern.name | translate}}</span>
      </div>
    </div>
  </div>
  <div class="bcc-loading" *ngIf="showLoading"></div>
</div>
