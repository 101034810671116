<div class="bcc-contour-width">
  <div class="row">
    <div class="col">
      <h2>{{'Contour width' | translate}}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-2 col-md-4 col-sm-6" *ngFor="let width of widths">
      <div class="bcc-card small" [routerLink]="['/']" [queryParams]="{contourWidth: width}" [queryParamsHandling]="'merge'">
        <span>{{width}}mm</span>
      </div>
    </div>
  </div>
  <div class="bcc-loading" *ngIf="showLoading"></div>
</div>
